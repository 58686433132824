import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        user: null, uid: null, activeOrg: null, venues: [], categories: [], actions: [], staff: [], activeOrgInfo: null, organisations: []
    },
    tagTypes: ['User'],
    reducers: {
        setUser: (state, action) => ({
            ...state,
            user: action.payload
        }),
        setOrganisations: (state, action) => ({
            ...state,
            organisations: action.payload
        }),
        setActiveOrg: (state, action) => ({
            ...state,
            activeOrg: action.payload
        }),
        setActiveOrgInfo: (state, action) => ({
            ...state,
            activeOrgInfo: action.payload
        }),
        setVenues: (state, action) => ({
            ...state,
            venues: action.payload
        }),
        setCategories: (state, action) => ({
            ...state,
            categories: action.payload
        }),
        setActions: (state, action) => ({
            ...state,
            actions: action.payload
        }),
        setStaff: (state, action) => ({
            ...state,
            staff: action.payload
        }),
        setUid: (state, action) => ({
            ...state,
            uid: action.payload
        }),
    },
})

// Action creators are generated for each case reducer function
export const {
    setUser, setUid, setActiveOrg, setVenues, setCategories, setActions, setStaff, setActiveOrgInfo, setOrganisations
} = userSlice.actions

export default userSlice.reducer