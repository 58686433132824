import React, { useState } from "react";


const Accordion = ({ children, label }) => {

    const [isOpen, setIsOpen] = useState(false)

    return <div>
        <div className='tabBar hover' style={isOpen ? { borderBottom: '1px solid #DCDCDC', backgroundColor: 'black', paddingTop: 0 } : { paddingTop: 0, borderBottom: '1px solid #DCDCDC', }} onClick={() => { setIsOpen(!isOpen) }}>
            <label style={{ display: 'flex', fontWeight: 'bold', marginTop: '15px', fontSize: '20px', color: isOpen ? 'white' : '#000', marginRight: '10px', marginLeft: '0px' }}>{isOpen ? '-' : '+'}</label>
            <label style={{ display: 'flex', fontWeight: 'bold', marginTop: '15px', fontSize: '15px', color: isOpen ? 'white' : '#000', }}>{label}</label>
        </div>
        {isOpen && children}
    </div>
}

export default Accordion