import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyAhwgUXhznRXfLbdxK7q38ghsqDdOq_tvw",
  authDomain: "precinctmanagementgroup.firebaseapp.com",
  projectId: "precinctmanagementgroup",
  storageBucket: "precinctmanagementgroup.appspot.com",
  messagingSenderId: "930983489984",
  appId: "1:930983489984:web:7303bf23021900af7a5825",
  measurementId: "G-KJD6EB85MN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)