import React from "react";


const RadioGroup = ({ options, value, setValue, label }) => {

    return <div>
        <label style={{ display: 'flex', marginTop: '15px', fontWeight: '800', fontSize: '14px', color: '#000' }}>{label || ''}</label>

        <div className="flexWrap" style={{ gap: '20px' }}>
            {options.map(option =>
                <div key={option} onClick={() => setValue(option)} className="hover">
                    <input type="radio" checked={value === option} />
                    <label style={{ marginLeft: '4px' }} className="hover">{option}</label>
                </div>
            )}

        </div>
    </div>
}

export default RadioGroup