import React, { useState, useEffect } from 'react'
import { getFirebaseDocs, getUrlParams } from '../common'
import { addHours, format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../assets/Logo.png';

import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase-config'
import SortIcon from '../assets/sort.png'
import { setActions, setCategories, setStaff, setVenues } from '../redux/userReducer';
import { hoursToAdd } from '../constants';

const ViewSummary = () => {


    const [incidents, setIncidents] = useState([])

    const venues = useSelector((state) => state.user.venues)
    const categories = useSelector((state) => state.user.categories)
    // const actions = useSelector((state) => state.user.actions)
    const staff = useSelector((state) => state.user.staff)
    const dispatch = useDispatch()
    const [orgInfo, setOrgInfo] = useState()

    useEffect(() => {
        const params = getUrlParams()
        if (params && params.orgId && params.id) {
            const orgId = params.orgId
            fetchOrg(orgId)
            fetchVenues(orgId)
            fetchCategories(orgId)
            fetchActions(orgId)
            fetchStaff(orgId)
            fetchIncidents(orgId, params.id)
        }
    }, [])


    const fetchOrg = async (orgId) => {
        const record = await (await getDoc(doc(db, 'organisations', orgId),)).data()
        setOrgInfo({ ...record, id: orgId })
    }
    const fetchVenues = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'venues'), where('orgId', '==', orgId))))
        dispatch(setVenues(records))
    }

    const fetchCategories = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'categories'), where('orgId', '==', orgId))))
        dispatch(setCategories(records))
    }

    const fetchActions = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'actions'), where('orgId', '==', orgId))))
        dispatch(setActions(records))
    }

    const fetchStaff = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'users'), where('organisations', 'array-contains-any', [{ id: orgId, role: 'admin' }, { id: orgId, role: 'user' }]))))
        dispatch(setStaff(records))
    }


    const fetchIncidents = async (orgId, id) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'incidents'), where('orgId', '==', orgId))))
        records.sort((a, b) => a.created > b.created ? -1 : 1)
        const summaryData = await (await getDoc(doc(db, 'summary', id))).data()
        if (summaryData) {
            setIncidents(records.filter(r => summaryData.records.includes(r.id)))
        }
    }

    const getLabel = (value, array, isArray, isUser) => {
        if (!value) {
            return '-'
        }

        if (isArray) {
            let values = []
            for (let v of value) {
                const matchingObj = array.find(r => r.id === v)
                if (matchingObj) {
                    values.push(matchingObj.name)
                }
            }
            return values.join(', ')
        }
        const matchingObj = array.find(r => r.id === value)
        if (matchingObj) {
            return isUser ? matchingObj.firstName + ' ' + matchingObj.lastName : matchingObj.name
        }
    }

    const getStatusColor = (status) => {
        if (status === 'Submitted') {
            return 'orange'
        } else if (status === 'Approved') {
            return 'green'
        }
    }

    const filteredIncidents = incidents


    return (<div>
        <div className="header" style={{ borderBottom: '1px solid #d3d3d3', paddingBottom: '10px', display: 'flex', alignItems: 'center', width: 'calc(100% - 20px)', marginLeft: '10px', justifyContent: 'space-between' }}>
            <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                <label>Powered By </label>
                <img src={Logo} style={{ width: '120px', height: '80px', marginLeft: '20px', objectFit: 'contain' }} />
            </div>
            <img src={orgInfo ? orgInfo.image : Logo} style={{ width: '120px', height: '80px', marginLeft: '20px', objectFit: 'contain' }} />
        </div>
        <div style={{ margin: '10px' }}>
            <>

                <table style={{ marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Report Id</th>
                            <th>Type</th>
                            <th>Staff</th>
                            <th>Report Date & Time</th>
                            <th>Incident Date & Time</th>
                            <th>Venue</th>
                            <th>Incident Category</th>
                            <th style={{ width: '60px' }}>Quantity</th>
                            <th style={{ width: '240px' }}>Summary</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredIncidents.map(row => <tr key={row.id}>
                            <td className='hover' style={{ textDecoration: 'underline' }}>{row.reportId || '-'}</td>
                            <td>{row.type}</td>
                            <td>{getLabel(row.userId, staff, false, true)}</td>
                            <td>{format(addHours(new Date(row.created),hoursToAdd), 'dd/MMM/yyyy), HH:mm')}</td>
                            <td>{format(addHours(new Date(row.incidentDate),hoursToAdd), 'dd/MMM/yyyy, HH:mm')}</td>
                            <td>{getLabel(row.venue, venues)}</td>
                            <td>{getLabel(row.category, categories, true)}</td>
                            <td style={{ width: '60px' }}>{row.quantity}</td>
                            <td style={{ overflowWrap: 'break-word', width: '240px' }}>{row.description}</td>
                            <td style={{ color: getStatusColor(row.status) }}>{row.status}</td>
                        </tr>)}
                    </tbody>
                </table>
            </>

        </div >
    </div >)
}

export default ViewSummary