import { createSlice } from '@reduxjs/toolkit'

export const eoiSlice = createSlice({
    name: 'eoiSlice',
    initialState: {
        eois: [], currentEoi: null,
        declarations: [], currentDeclaration: null,
        qldAssessments: [], currentQldAssessment: null,
        qldEois: [], currentQldEoi: null,
    },
    tagTypes: ['Eoi'],
    reducers: {
        setEois: (state, action) => ({
            ...state,
            eois: action.payload
        }),
        clearEois: (state, action) => ({
            ...state,
            eois: [], currentEoi: null
        }),
        setCurrenEoi: (state, action) => ({
            ...state,
            currentEoi: action.payload
        }),

        setQldEois: (state, action) => ({
            ...state,
            qldEois: action.payload
        }),
        clearQldEois: (state, action) => ({
            ...state,
            qldEois: [], currentQldEoi: null
        }),
        setCurrenQldEoi: (state, action) => ({
            ...state,
            currentQldEoi: action.payload
        }),


        setDeclarations: (state, action) => ({
            ...state,
            declarations: action.payload
        }),
        clearDeclarations: (state, action) => ({
            ...state,
            declarations: [], currentDeclaration: null
        }),
        setCurrenDeclaration: (state, action) => ({
            ...state,
            currentDeclaration: action.payload
        }),
        setQldAssessments: (state, action) => ({
            ...state,
            qldAssessments: action.payload
        }),
        clearQldAssessments: (state, action) => ({
            ...state,
            qldAssessments: [], currentQldAssessment: null
        }),
        setCurrentQldAssessment: (state, action) => ({
            ...state,
            currentQldAssessment: action.payload
        })
    },
})

// Action creators are generated for each case reducer function
export const {
    setEois, clearEois, setCurrenEoi,
    setQldEois, clearQldEois, setCurrenQldEoi,
    setDeclarations, clearDeclarations, setCurrenDeclaration,
    setCurrentQldAssessment, setQldAssessments, clearQldAssessments
} = eoiSlice.actions

export default eoiSlice.reducer