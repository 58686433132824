import React, { useState } from 'react';
import Select from 'react-select';


const Dropdown = ({ options, clearable, isMulti, disabled, customStyle, value, setValue, placeholder, label, errorFields, name }) => {

    let style = customStyle || {}
    return (<div className="flex-wrap">
        <label style={{ display: 'flex', fontWeight: 'bold', marginTop: '15px', fontSize: '15px', color: '#000', ...style }}>{label || ''}</label>
        <Select
            isMulti={!!isMulti}
            isDisabled={disabled}
            className={errorFields.includes(name) ? 'basic-single-error' : "basic-single"}
            classNamePrefix="select"
            isClearable={!!clearable}
            placeholder={placeholder || ''}
            value={value}
            // options={options.map(v => ({ label: v, value: v }))}
            options={options}
            onChange={setValue}
        />
    </div>)
}

export default Dropdown