import React from 'react'

const Checkbox = ({ label, value, setValue, disabled, errors, name }) => {
    return (
        <div className={`checkbox ${errors && errors.includes(name) ? 'error' : ''}`}>
            <input type="checkbox" disabled={disabled} checked={value} onChange={() => setValue(!value)} />
            <label onClick={() => !disabled && setValue(!value)}> {label}</label>
        </div>
    )
}

export default Checkbox