import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyCalendar = ({ startDate, setStartDate, label, minDate = {}, showTimeOnly, showDateOnly }) => {

    return (<div className="flex-wrap">
        <label style={{ fontWeight: 'bold', marginRight: '10px', marginTop: '15px', display: 'flex' }}>{label || 'Date & Time'}</label>
        <DatePicker selected={startDate} {...minDate} maxDate={new Date()} dateFormat={showDateOnly ? 'dd/MM/yyyy' : showTimeOnly ? 'HH:mm a' : 'dd/MM/yyyy HH:mm'} showTimeSelect={!showDateOnly} timeFormat='HH:mm' timeIntervals={1} onChange={(date) => setStartDate(date)} />
    </div>);
}

export default MyCalendar