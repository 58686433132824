import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { storage } from '../firebase-config'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import edit from '../assets/edit.png'
import pdf from '../assets/pdf.png'
import heic2any from "heic2any";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    position: 'relative',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    maxWidth: '300px',
    margin: 'auto'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function MyDropzone({ label, storageLocation, errors, name, editing, image, updateImage, allowPdf, customMessage }) {
    const [editingImage, setEditingImage] = useState(false)

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        // console.log('acceptedFiles', acceptedFiles)

        const file = acceptedFiles[0]
        const extension = file.type.replace('image/', '').replace('application/', '')


        if (extension === 'heic' || extension === 'heif') {
            heic2any({
                blob: file,  // Use the original file object
                toType: "image/jpeg",
                quality: 0.7, // adjust quality as needed
            }).then(outputBlob => {
                const fileNew = new File([outputBlob], "heic" + ".jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
                doUploadFile(fileNew, extension)
            })
        } else {
            doUploadFile(file, extension)
        }

    }, [])


    const doUploadFile = (file, extension) => {
        const mountainsRef = ref(storage, `${storageLocation}.${extension}`);

        uploadBytes(mountainsRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                updateImage({ url: downloadURL, pdf: extension.includes('pdf') })
                setEditingImage(false)
            })
        })
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: allowPdf ? { 'image/*': [], 'application/pdf': [] } : { 'image/*': [] }, onDrop });


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ])



    useEffect(() => {
        if (editing && !image && !editingImage) {
            setEditingImage(true)
        }
    }, [editing])


    useEffect(() => {
        if (errors.includes(name)) {
            document.getElementById(name).classList.add('errorImage')
        } else {
            document.getElementById(name).classList.remove('errorImage')
        }
    }, [errors])


    return (<div id={name} className="container" style={{ marginTop: '30px' }}>
        <label style={{ display: 'flex', marginTop: '15px', fontWeight: '800', fontSize: '14px', color: '#000' }}>{label || ''}</label>
        <div {...getRootProps({ style })}>

            {editingImage ?
                <>
                    <input {...getInputProps()} />
                    <p>{customMessage || `Drop an image or click here to add one.`}</p>
                </> : <>
                    {editing && <img src={edit} style={{ width: '20px', height: '20px', position: 'absolute', top: '10px', right: '10px' }} onClick={() => setEditingImage(true)} />}
                    {image && image.pdf ?
                        <img src={pdf} style={{ width: '100%', height: '100%' }} />
                        :
                        <img src={image.url} style={{ width: '100%', height: '100%' }} />
                    }
                </>
            }
        </div>
    </div>)
}