import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import Login from './Pages/Login'
import HomeNew from './Pages/HomeNew'
import Dashboard from './Pages/Dashboard'
// import ViewListing from './ViewListing'
import './index.css'
import { Provider } from "react-redux";
import { store } from "./redux/store";

import Settings from "./Pages/Settings";
import ViewSummary from "./Pages/ViewSummary";
import ViewDetailed from "./Pages/ViewDetailed";
// import Declaration from "./Pages/Declaration";
// import Traffic from "./Pages/Traffic";
// import DeclarationTraffic from "./Pages/DeclarationTraffic";
// import Links from "./Pages/Links";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeNew />,
  },
  {
    path: "/viewSummary",
    element: <ViewSummary />,
  },
  {
    path: "/viewDetailed",
    element: <ViewDetailed />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/settings",
    element: <Settings />,
  }
])

createRoot(document.getElementById("root")).render(
  <>
    <Provider store={store()}>
      <RouterProvider router={router} />
      <ToastContainer />
    </Provider>
  </>
);