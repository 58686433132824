const { format } = require("date-fns")

const getUrlParams = () => {
    const url = window.location.href.split('?')[1]
    const urlParams = new URLSearchParams(url)
    const paramsObj = Object.fromEntries(urlParams)
    return paramsObj
}

const getFirebaseDocs = snapshot => {
    var data = []
    for (let row of snapshot.docs) {
        data.push({ id: row.id, ...row.data() })
    }
    return data
}

const removeTrailingSlash = social => {
    if (social[social.length - 1] === '/') {
        social = social.slice(0, -1);
    }
    return social
}

const getSeconds = () => parseInt(new Date().getTime() / 1000)


const randomString = (length = 30) => [...Array(length)].map(() => Math.random().toString(36)[2]).join('')

const reverseDateString = (date, hasDate) => {
    if (hasDate) {
        return date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0]
    } else {
        return date.split('-')[1] + '/' + date.split('-')[0] + '/' + format(new Date(), 'dd')
    }
}

const checkEmailInValid = (email) => !email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)


module.exports = { getUrlParams, getFirebaseDocs, randomString, reverseDateString, checkEmailInValid, getSeconds, removeTrailingSlash }