import React, { useEffect, useState } from "react";
import Logo from '../assets/Logo.png';
import { auth } from '../firebase-config'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUid } from "../redux/userReducer";
import { useDispatch } from "react-redux";

const Login = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState('')

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleLogin = async () => {
    try {
      setLoading(true)
      const { user } = await signInWithEmailAndPassword(auth, email, password)
      // console.log('user', user)
      window.localStorage.removeItem('precinct-time')
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error('e', e)
      toast.error('Check your credentials.', { position: 'top-center' })
    }
  }



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // const uid = user.uid
        dispatch(setUid(user.uid))
        navigate('/')
      } else {
        // User is signed out
        // ...
      }
    })

    return () => unsubscribe()
  }, [])


  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <div style={{ display: 'flex', flexDirection: 'column', width: '350px', alignItems: 'center' }}>
      <img src={Logo} style={{ width: '200px', marginLeft: '20px' }} />

      <h1 style={{ fontSize: '30px' }}>Login</h1>
      <input type="email" id="email" required name="email" placeholder="email@domain.com.au" value={email} onChange={({ target }) => setEmail(target.value)} />
      <input type="password" id="password" required name="password" placeholder="Password" value={password} onChange={({ target }) => setPassword(target.value)} />
      <button disabled={loading} onClick={handleLogin}>{loading ? 'Loading...' : 'Login'}</button>
    </div>
  </div>
}

export default Login