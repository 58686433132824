import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase-config";
import Logo from '../assets/Logo.png';
import Hamburger from '../assets/hamburger.png';
import Close from '../assets/close.png';

import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { setActions, setActiveOrg, setActiveOrgInfo, setCategories, setStaff, setUid, setUser, setVenues } from "../redux/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { getFirebaseDocs } from "../common";
import Incidents from '../assets/Incidents.png'
import Settings from '../assets/Settings.png'
import { toast } from "react-toastify";
import { differenceInMinutes } from "date-fns";

const Header = ({ triggerRefetch, setTriggerRefetch }) => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const uid = useSelector((state) => state.user.uid)
    const activeOrg = useSelector((state) => state.user.activeOrg)
    const userInfo = useSelector((state) => state.user.user)
    const activeOrgInfo = useSelector((state) => state.user.activeOrgInfo)

    const [isOpen, setIsOpen] = useState(false)


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/login')
            } else {
                dispatch(setUid(user.uid))
            }
        })
        return () => unsubscribe()
    }, [])


    const onFocus = () => {

        const time = window.localStorage.getItem('precinct-time')

        if (time) {

            const lastViewTime = new Date(time)

            const minDiff = differenceInMinutes(new Date(), lastViewTime)
            if (minDiff > 30) {
                window.localStorage.removeItem('precinct-time')
                signOutUser()
            }
        }

        // console.log("Tab is in focus");
    };

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
        // console.log("Tab is blurred");
        window.localStorage.setItem('precinct-time', new Date().toISOString())
    };


    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        // Calls onFocus when the window first loads
        onFocus();
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    useEffect(() => {
        if (uid) {
            getUserInfo(uid)
        }
    }, [uid])


    useEffect(() => {
        if (userInfo && !activeOrg) {
            dispatch(setActiveOrg(userInfo.organisations[0]))
        }
    }, [userInfo, activeOrg])

    useEffect(() => {
        if (activeOrg) {
            fetchVenues(activeOrg.id)
            fetchCategories(activeOrg.id)
            fetchActions(activeOrg.id)
            fetchStaff(activeOrg.id)
            fetchOrgInfo(activeOrg.id)
            if (userInfo && userInfo.isSuperAdmin) {
                // Fetch orgs again.
                getUserInfo(userInfo.id)
            }
            setTriggerRefetch && setTriggerRefetch(false)
        }
    }, [activeOrg, triggerRefetch])


    const fetchOrgInfo = async (uid) => {
        const orgData = ((await getDoc(doc(db, 'organisations', uid))).data())
        dispatch(setActiveOrgInfo({ ...orgData, id: uid }))
    }

    const getUserInfo = async (uid) => {
        const userData = ((await getDoc(doc(db, 'users', uid))).data())


        if (userData.status !== 'Active') {
            toast.error('Your account is deactivated.')
            setTimeout(() => {
                signOutUser()
            }, 2000)
            return
        }

        if (userData.isSuperAdmin) {
            // Add all orgs to users orgs.
            const orgs = await getFirebaseDocs(await getDocs(query(collection(db, 'organisations'))))
            dispatch(setUser({ ...userData, organisations: orgs.map(r => ({ id: r.id, role: 'admin' })), id: uid }))
        } else {
            dispatch(setUser({ ...userData, id: uid }))
        }

    }


    const fetchVenues = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'venues'), where('orgId', '==', orgId))))
        dispatch(setVenues(records))
    }

    const fetchCategories = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'categories'), where('orgId', '==', orgId))))
        dispatch(setCategories(records))
    }

    const fetchActions = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'actions'), where('orgId', '==', orgId))))
        dispatch(setActions(records))
    }

    const fetchStaff = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'users'), where('organisations', 'array-contains-any', [{ id: orgId, role: 'admin' }, { id: orgId, role: 'user' }]))))
        dispatch(setStaff(records))
    }

    const signOutUser = async () => {
        await auth.signOut();
        window.location.reload()
    }

    const menuItems = [
        { label: 'Incidents', link: '/', icon: Incidents },
        { label: 'Dashboard', link: '/dashboard' },
        // { label: 'Reports', link: '/reports' },
        { label: 'Settings', link: '/settings', icon: Settings, isAdmin: true },
    ].filter(row => (row.isAdmin && (!activeOrg || (activeOrg && activeOrg.role !== 'admin'))) ? false : true)

    return (<div className="header" style={{ borderBottom: '1px solid #d3d3d3', paddingBottom: '10px', display: 'flex', alignItems: 'center', width: 'calc(100% - 20px)', marginLeft: '10px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Logo} className="logo" style={{ width: '180px', marginLeft: '20px' }} />
            <h3 style={{ marginLeft: '10px' }}>Welcome, {userInfo?.firstName}</h3>
        </div>
        {isOpen && <div className="mobile-menu hide-print">
            {menuItems.map(({ label, icon, link }) => <div key={label} className="hover" onClick={() => { navigate(link); }} style={{ padding: '10px 0', borderBottom: '1px solid #DCDCDC', fontWeight: window.location.pathname === link ? '800' : '500' }}>
                {/* <img src={icon} style={{ width: '15px', height: '15px' }} /> */}
                {label}</div>)}
        </div>}
        <div className="hide-mobile navLinks hide-print">
            {menuItems.map(({ label, link, icon }) => <div key={label} className="hover" onClick={() => { navigate(link); }} style={{ padding: '10px 0', borderBottom: '1px solid #DCDCDC', color: window.location.pathname === link ? '#004d72' : 'black', fontWeight: window.location.pathname === link ? '800' : '500', }}>
                {/* <img src={icon} style={{ width: '15px', height: '15px', marginTop: '22px' }} /> */}
                <label>{label}</label>
            </div>)}
        </div>
        <img className="show-mobile hide-print" src={isOpen ? Close : Hamburger} style={{ width: '30px', height: '30px' }} onClick={() => setIsOpen(!isOpen)} />
        <button className="hide-mobile hide-print" style={{ marginTop: 0, padding: '10px', borderRadius: '4px' }} onClick={signOutUser}> Sign out</button>
    </div >)
}

export default Header