import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import eoiReducer from './eoiReducer'
import userReducer from './userReducer'

export const store = (preloadedState) => configureStore({
  reducer: {
    eoi: eoiReducer,
    user: userReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([]),
})

setupListeners(store().dispatch)