import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        minWidth: '300px',
        width: '85%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        maxWidth: '500px',
        flexDirection: 'column'
    },
    overlay: {
        zIndex: 10,
    }
}

const ModalComponent = ({ showModal, setShowModal, children }) => {

    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={customStyles}
            contentLabel="Example Modal">
            {children}
        </Modal>
    );
}

export default ModalComponent