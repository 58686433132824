import React from "react";

const Input = ({ type, label, multiline, inputMode, placeholder, preventPaste, value, setValue, disabled, errors, name, errorMessage }) => {

    return (<div>
        <label style={{ display: 'flex', marginTop: '15px', fontWeight: '800', fontSize: '14px', color: '#000' }}>{label || ''}</label>
        {
            multiline ? <textarea disabled={!!disabled} className={errors.includes(name) ? 'error' : ''} type={type} placeholder={placeholder || ''} value={value} onChange={(e) => setValue(e.target.value)} >

            </textarea> :

                <input inputMode={inputMode} onPaste={(e) => { preventPaste && e.preventDefault(); return false }} autoComplete="false" disabled={!!disabled} className={errors.includes(name) ? 'error' : ''} type={type} placeholder={placeholder || ''} value={value} onChange={(e) => setValue(e.target.value)} />
        }
        {errorMessage && <p style={{ margin: 0, fontSize: '12px', color: 'red' }}>{errorMessage}</p>}
    </div>)

}

export default Input