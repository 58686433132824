import React, { useState, useRef, useEffect } from 'react'

import Input from '../components/Input'
import Dropdown from '../components/Dropdown'
import { getFirebaseDocs, getUrlParams, randomString, reverseDateString } from '../common'
import MyDropzone from '../components/MyDropzone'
import Checkbox from '../components/Checkbox'
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios'
import { format, isAfter, isBefore } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import MyCalendar from '../components/Calendar'
import Radio from '../components/Radio'
import { trainers, types } from '../constants'
import AddressSearch from '../components/AddressSearch'
import { useDispatch, useSelector } from 'react-redux'
import Header from "../components/Header";
import { addDoc, collection, doc, getCountFromServer, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../firebase-config'
import { setUser } from '../redux/userReducer'
import ModalComponent from '../components/Modal'
import RadioGroup from '../components/RadioGroup'
import Accordion from '../components/Accordion'
import SortIcon from '../assets/sort.png'
import { toast } from 'react-toastify'

import { startOfDay } from 'date-fns'

const Home = () => {

    const [page, setPage] = useState(0)
    const [errors, setErrors] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showTypes, setShowTypes] = useState(false)
    const [type, setType] = useState('')
    const [previewIncident, setPreviewIncident] = useState(false)
    const [previewWitnessIndex, setPreviewWitnessIndex] = useState(0)
    const [previewInterestIndex, setPreviewInterestIndex] = useState(0)
    const [previewMode, setPreviewMode] = useState('Overview')
    const [incidents, setIncidents] = useState([])

    const [search, setSearch] = useState('')

    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showExportModal, setShowExportModal] = useState(false)

    const [reportName, setReportName] = useState('')
    const [reportType, setReportType] = useState('')
    const [filterVenue, setFilterVenue] = useState('')
    const [filterStaff, setFilterStaff] = useState('')
    const [startIncidentFilterDate, setStartIncidentFilterDate] = useState('')
    const [endIncidentFilterDate, setEndIncidentFilterDate] = useState('')


    const [viewIncident, setViewIncident] = useState(false)
    const [editIncident, setEditIncident] = useState(false)
    const [oldIncident, setOldIncident] = useState()
    const [incidentDate, setIncidentDate] = useState(new Date())
    const [venue, setVenue] = useState('')
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState([])
    // const [action, setAction] = useState([])
    const [quantity, setQuantity] = useState('0')
    const [attachment, setAttachment] = useState('')
    const [policeAttendance, setPoliceAttendance] = useState('No')
    const [policeName, setPoliceName] = useState('')

    const [status, setStatus] = useState('Submitted')

    const [interests, setInterests] = useState([{}])
    const [viewInterestIndex, setViewInterestIndex] = useState(0)


    const [interestName, setInterestName] = useState('')
    const [interestGender, setInterestGender] = useState('')
    const [interestAge, setInterestAge] = useState('')
    const [interestLicense, setInterestLicense] = useState('')
    const [interestPhone, setInterestPhone] = useState('')
    const [interestEmail, setInterestEmail] = useState('')
    const [interestOther, setInterestOther] = useState('')

    const [witnesses, setWitnesses] = useState([{}])
    const [viewWitnessIndex, setViewWitnessIndex] = useState(0)

    const [witnessName, setWitnessName] = useState('')
    const [witnessGender, setWitnessGender] = useState('')
    const [witnessAge, setWitnessAge] = useState('')
    const [witnessLicense, setWitnessLicense] = useState('')
    const [witnessPhone, setWitnessPhone] = useState('')
    const [witnessEmail, setWitnessEmail] = useState('')
    const [witnessOther, setWitnessOther] = useState('')

    const [selectedRows, setSelectedRows] = useState([])

    const [showVersion, setShowVersion] = useState(false)
    const [sortedColumn, setSortedColumn] = useState({ property: '', type: 'asc' })

    const userInfo = useSelector((state) => state.user.user)
    const venues = useSelector((state) => state.user.venues)
    const categories = useSelector((state) => state.user.categories)
    // const actions = useSelector((state) => state.user.actions)
    const staff = useSelector((state) => state.user.staff)
    const activeOrg = useSelector((state) => state.user.activeOrg)
    const activeOrgInfo = useSelector((state) => state.user.activeOrgInfo)

    const hideLiquorField = type === types[0]

    useEffect(() => {
        if (activeOrg) {
            fetchIncidents(activeOrg.id)
            restoreFilters()
        }
    }, [activeOrg])


    const restoreFilters = () => {
        const filtersFromStorage = window.localStorage.getItem(`filters-${activeOrg.id}`)
        if (filtersFromStorage) {
            const filterObj = JSON.parse(filtersFromStorage)

            setFilterVenue(filterObj.filterVenue)
            setFilterStaff(filterObj.filterStaff)

            setStartIncidentFilterDate(filterObj.startIncidentFilterDate ? new Date(filterObj.startIncidentFilterDate) : '')
            setEndIncidentFilterDate(filterObj.endIncidentFilterDate ? new Date(filterObj.endIncidentFilterDate) : '')
        }
    }


    const fetchIncidents = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'incidents'), where('orgId', '==', orgId))))
        records.sort((a, b) => a.created > b.created ? -1 : 1)
        setIncidents(records)
    }


    const downloadBlob = (content, filename, contentType) => {
        // Create a blob
        var blob = new Blob([content], { type: contentType });
        var url = URL.createObjectURL(blob);

        // Create a link to download it
        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
    }


    const downloadCsv = async () => {
        setLoading(true)
        const resp = await axios.post('https://generatecsv-oqysrnziza-ts.a.run.app', {
            // const resp = await axios.post('http://127.0.0.1:5001/precinctmanagementgroup/australia-southeast1/generateCsv', {
            ids: selectedRows.map(r => r.id),
            orgId: activeOrg.id
        }, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        })
        const csvData = resp.data.map(r => r.join(', ')).join('\r\n')
        console.log('csvData', csvData)
        downloadBlob(csvData, `${reportName} - ${reportType} - ${format(new Date(), 'dd-MMM-yyyy')}.csv`, 'text/csv;charset=utf-8;')
        setLoading(false)
    }

    const generatePDf = async () => {
        setLoading(true)

        try {
            const resp = await axios.post('https://generatepdf-oqysrnziza-ts.a.run.app', {
                // const resp = await axios.post('http://127.0.0.1:5001/precinctmanagementgroup/australia-southeast1/generatePDF', {
                ids: selectedRows.map(r => r.id),
                orgId: activeOrg.id,
                name: reportName,
                reportType,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                // responseType: 'arraybuffer'
            })


            const downloadLink = document.createElement("a");

            downloadLink.href = 'data:application/pdf;base64,' + resp.data;

            downloadLink.download = `${reportName} - ${reportType} - ${format(new Date(), 'dd-MMM-yyyy')}.pdf`;

            downloadLink.click();

            toast.success('PDF Created')
        } catch (e) {
            console.error('e', e)
            toast.error('error occurred' + e.message)
        }
        setLoading(false)

    }

    const handleSave = async () => {

        const additionalPayload = hideLiquorField ? {} : {
            policeAttendance,
            attachment: attachment || '',
            policeName: policeAttendance ? policeName : '',
            interests: interests.map((r, idx) => idx === viewInterestIndex ? { ...r, name: interestName, age: interestAge, email: interestEmail, gender: interestGender, license: interestLicense, other: interestOther, phone: interestPhone } : r),
            witnesses: witnesses.map((r, idx) => idx === viewWitnessIndex ? { ...r, name: witnessName, age: witnessAge, email: witnessEmail, gender: witnessGender, license: witnessLicense, other: witnessOther, phone: witnessPhone } : r)
        }

        if (!editIncident) {
            const reportsCount = (await getCountFromServer(query(collection(db, 'incidents'), where('orgId', '==', activeOrg.id)))).data().count
            console.log('reports Count', reportsCount)
            await addDoc(collection(db, 'incidents'), {
                incidentDate: incidentDate.toISOString(),
                venue: venue.value,
                type,
                category: category.map(r => r.value),
                quantity: quantity,
                description,
                orgId: activeOrg.id,
                ...additionalPayload,
                status: status,
                reportId: `${activeOrgInfo.prefix}-${reportsCount + 1}`,
                created: new Date().toISOString(),
                userId: userInfo.id,
                versions: [],
                events: [{ date: new Date().toISOString(), message: `Created by ${userInfo.firstName} ${userInfo.lastName}` }],
            })

            toast.success('Incident created', { position: 'top-center' })
        } else {

            const { versions, ...strippedOldIncident } = oldIncident
            console.log('editIncident', editIncident)
            await updateDoc(doc(db, 'incidents', editIncident), {
                incidentDate: incidentDate.toISOString(),
                venue: venue.value,
                type,
                category: category.map(r => r.value),
                quantity: quantity,
                description,
                orgId: activeOrg.id,
                ...additionalPayload,
                status: status,
                // created: new Date().toISOString(),
                // userId: userInfo.id,
                updatedBy: userInfo.id,
                updated: new Date().toISOString(),
                versions: [...oldIncident.versions, strippedOldIncident],
                events: [...oldIncident.events,
                { date: new Date().toISOString(), message: `Updated by ${userInfo.firstName} ${userInfo.lastName}` }
                ],
            })
            toast.success('Incident updated', { position: 'top-center' })

        }

        setViewIncident(false)
        setEditIncident(false)
        fetchIncidents(activeOrg.id)
    }


    useEffect(() => {
        if (viewIncident) {
            const matchingPerson = interests.find((r, idx) => idx == viewInterestIndex)
            if (matchingPerson) {
                setInterestAge(matchingPerson.age || '')
                setInterestGender(matchingPerson.gender || '')
                setInterestName(matchingPerson.name || '')
                setInterestLicense(matchingPerson.license || '')
                setInterestPhone(matchingPerson.phone || '')
                setInterestEmail(matchingPerson.email || '')
                setInterestOther(matchingPerson.other || '')
            }
        }
    }, [viewIncident, viewInterestIndex])

    useEffect(() => {
        if (viewIncident) {
            const matchingPerson = witnesses.find((r, idx) => idx == viewWitnessIndex)
            if (matchingPerson) {
                setWitnessAge(matchingPerson.age || '')
                setWitnessGender(matchingPerson.gender || '')
                setWitnessName(matchingPerson.name || '')
                setWitnessLicense(matchingPerson.license || '')
                setWitnessPhone(matchingPerson.phone || '')
                setWitnessEmail(matchingPerson.email || '')
                setWitnessOther(matchingPerson.other || '')
            }
        }
    }, [viewIncident, viewWitnessIndex])

    const toggleWitness = index => {
        if (viewWitnessIndex !== index) {
            // save current info
            setWitnesses(witnesses.map((r, idx) => idx === viewWitnessIndex ? { ...r, name: witnessName, age: witnessAge, email: witnessEmail, gender: witnessGender, license: witnessLicense, other: witnessOther, phone: witnessPhone } : r))
        }
        setViewWitnessIndex(index)
    }


    const addWitness = () => {
        let newWitness = witnesses.map((r, idx) => idx === viewWitnessIndex ? { ...r, name: witnessName, age: witnessAge, email: witnessEmail, gender: witnessGender, license: witnessLicense, other: witnessOther, phone: witnessPhone } : r)
        setWitnesses([...newWitness, {}])
        setViewWitnessIndex(newWitness.length)
    }

    const removeWitness = () => {
        setWitnesses(witnesses.filter((r, idx) => idx !== viewWitnessIndex))
        setTimeout(() => {
            setViewWitnessIndex(viewWitnessIndex !== 0 ? viewWitnessIndex - 1 : 0)
        }, 500)
    }


    const toggleInterest = index => {
        if (viewInterestIndex !== index) {
            // save current info
            setInterests(interests.map((r, idx) => idx === viewInterestIndex ? { ...r, name: interestName, age: interestAge, email: interestEmail, gender: interestGender, license: interestLicense, other: interestOther, phone: interestPhone } : r))
        }
        setViewInterestIndex(index)
    }

    const addInterest = () => {
        let newInterest = interests.map((r, idx) => idx === viewInterestIndex ? { ...r, name: interestName, age: interestAge, email: interestEmail, gender: interestGender, license: interestLicense, other: interestOther, phone: interestPhone } : r)
        setInterests([...newInterest, {}])
        setViewInterestIndex(newInterest.length)
    }

    const removeInterest = () => {
        setInterests(interests.filter((r, idx) => idx !== viewInterestIndex))
        setTimeout(() => {
            setViewInterestIndex(viewInterestIndex !== 0 ? viewInterestIndex - 1 : 0)
        }, 500)
    }


    const getLabel = (value, array, isArray, isUser) => {
        if (!value) {
            return '-'
        }

        if (isArray) {
            let values = []
            for (let v of value) {
                const matchingObj = array.find(r => r.id === v)
                if (matchingObj) {
                    values.push(matchingObj.name)
                }
            }
            return values.join(', ')
        }
        const matchingObj = array.find(r => r.id === value)
        if (matchingObj) {
            return isUser ? matchingObj.firstName + ' ' + matchingObj.lastName : matchingObj.name
        }
    }



    const updateDropdown = (value, array, isArray) => {

        if (isArray) {
            let values = []
            for (let v of value) {
                const matchingObj = array.find(r => r.id === v)
                if (matchingObj) {
                    values.push({ label: matchingObj.name, value: matchingObj.id })
                }
            }
            return values
        }
        const matchingObj = array.find(r => r.id === value)
        if (matchingObj) {
            return { label: matchingObj.name, value: matchingObj.id }
        }
    }



    const handleEdit = row => {

        setStatus(row.status)
        setEditIncident(row.id)
        setType(row.type)
        setDescription(row.description)
        setIncidentDate(new Date(row.incidentDate))
        setVenue(updateDropdown(row.venue, venues, false))

        setOldIncident(row)
        setPoliceAttendance(row.policeAttendance)
        setPoliceName(row.policeName)

        setCategory(row.category ? updateDropdown(row.category, categories, true) : [])
        setQuantity(row.quantity)

        if (row.type === types[1]) {
            setInterests(row.interests)
            setWitnesses(row.witnesses)
        }
        setViewIncident(true)
        setPreviewIncident(false)
    }


    const handleCreateNewIncident = () => {
        setShowTypes(false)
        setOldIncident()
        setIncidentDate(new Date())
        setVenue('')
        setDescription('')
        setCategory([])
        // setAction([])
        setQuantity('0')
        setPoliceAttendance('No')
        setPoliceName('')
        setWitnesses([{}])
        setViewWitnessIndex(0)
        setInterests([{}])
        setViewInterestIndex(0)
        setEditIncident(false)
        setViewIncident(true)
        setStatus('Submitted')
    }

    const handleApproveIncident = async (row) => {
        await updateDoc(doc(db, 'incidents', row.id), {
            status: 'Approved',
            approvedBy: userInfo.id,
            events: [...row.events, { date: new Date().toISOString(), message: `Approved by ${userInfo.firstName} ${userInfo.lastName}` }],
        })
        setPreviewIncident(false)
        fetchIncidents(activeOrg.id)
        toast.success('Incident approved', { position: 'top-center' })
    }

    const handleMassApprove = async () => {

        for (let row of selectedRows) {

            if (row.status !== 'Approved') {
                await updateDoc(doc(db, 'incidents', row.id), {
                    status: 'Approved',
                    approvedBy: userInfo.id,
                    events: [...row.events, { date: new Date().toISOString(), message: `Approved by ${userInfo.firstName} ${userInfo.lastName}` }],
                })
            }
        }
        fetchIncidents(activeOrg.id)
        toast.success('Incidents approved', { position: 'top-center' })
    }


    const handlePreviewViewIncident = row => {
        setPreviewWitnessIndex(0); setPreviewInterestIndex(0); setPreviewIncident(row)
    }

    const getStatusColor = (status) => {
        if (status === 'Submitted') {
            return 'orange'
        } else if (status === 'Approved') {
            return 'green'
        }
    }


    const handleSort = property => {
        if (sortedColumn.property === property && sortedColumn.type === 'asc') {
            // Toggle sort
            setIncidents([...incidents.reverse()])
            setSortedColumn({ property: property, type: 'desc' })

        } else {
            // Start new sort

            switch (property) {
                case 'reportId':
                    setIncidents([...incidents.filter(r => r.reportId).sort((a, b) => a.reportId.toLowerCase() > b.reportId.toLowerCase() ? 1 : -1)])
                    break;
                case 'type':
                    setIncidents([...incidents.sort((a, b) => a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1)])
                    break;
                case 'userId':
                    setIncidents([...incidents.sort((a, b) => getLabel(a.userId, staff, false, true).toLowerCase() > getLabel(b.userId, staff, false, true).toLowerCase() ? 1 : -1)])
                    break;
                case 'created':
                    setIncidents([...incidents.sort((a, b) => new Date(a.created) > new Date(b.created) ? 1 : -1)])
                    break;
                case 'incidentDate':
                    setIncidents([...incidents.sort((a, b) => new Date(a.incidentDate) > new Date(b.incidentDate) ? 1 : -1)])
                    break;
                case 'venue':
                    setIncidents([...incidents.sort((a, b) => getLabel(a.venue, venues).toLowerCase() > getLabel(b.venue, venues).toLowerCase() ? 1 : -1)])
                    break;
                case 'category':
                    setIncidents([...incidents.sort((a, b) => getLabel(a.category, categories, true).toLowerCase() > getLabel(b.category, categories, true).toLowerCase() ? 1 : -1)])
                    break;
                case 'quantity':
                    setIncidents([...incidents.sort((a, b) => parseInt(a.quantity || '0') > parseInt(b.quantity || '0') ? 1 : -1)])
                    break
                case 'description':
                    setIncidents([...incidents.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1)])
                    break
                case 'status':
                    setIncidents([...incidents.sort((a, b) => a.status.toLowerCase() > b.status.toLowerCase() ? 1 : -1)])
                    break

            }
            setSortedColumn({ property: property, type: 'asc' })
        }
    }

    const clearFilters = () => {
        window.localStorage.removeItem(`filters-${activeOrg.id}`)
        setFilterVenue('')
        setFilterStaff('')
        setEndIncidentFilterDate('')
        setStartIncidentFilterDate('')
        setShowFilterModal(false)
    }

    const applyFilter = () => {
        // Save filter to storage
        window.localStorage.setItem(`filters-${activeOrg.id}`, JSON.stringify({ filterVenue, filterStaff, startIncidentFilterDate, endIncidentFilterDate }))

        if (endIncidentFilterDate && startIncidentFilterDate) {
            if (isAfter(startIncidentFilterDate, endIncidentFilterDate)) {
                toast.error('End Date must be after start date')
            }
        }
        setShowFilterModal(false)
    }

    const applyReport = async () => {
        if (reportType.includes('Excel')) {
            await downloadCsv()
        } else if (reportType.includes('PDF')) {
            await generatePDf()

        }
        setShowExportModal(false)
    }


    const filteredIncidents = incidents.filter(r => filterVenue ? r.venue === filterVenue.value : true).filter(r => startIncidentFilterDate ? isAfter(new Date(r.incidentDate), startIncidentFilterDate) : true).filter(r => endIncidentFilterDate ? isBefore(new Date(r.incidentDate), endIncidentFilterDate) : true).filter(r => filterStaff ? r.userId === filterStaff.value : true).map(row => ({ ...row, staff: getLabel(row.userId, staff, false, true), ven: getLabel(row.venue, venues), cat: getLabel(row.category, categories, true), })).filter(r => !search ? true : ((r.cat && r.cat.toLowerCase().includes(search.toLowerCase())) || (r.description && r.description.toLowerCase().includes(search.toLowerCase())) || (r.ven && r.ven.toLowerCase().includes(search.toLowerCase())) || (r.staff && r.staff.toLowerCase().includes(search.toLowerCase()))))

    const toggleAllSelected = () => {
        if (selectedRows.length === 0) {
            setSelectedRows([...filteredIncidents])
        } else {
            setSelectedRows([])
        }
    }

    const toggleSelection = (row) => {
        if (selectedRows.map(r => r.id).includes(row.id)) {
            setSelectedRows([...selectedRows.filter(r => r.id !== row.id)])
        } else {
            setSelectedRows([...selectedRows, row])
        }
    }


    return (<div>
        <Header />

        <div style={{ margin: '10px' }}>
            {errorMessage && errors.length > 0 && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            {!viewIncident && <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <button className='tabButtonActive' onClick={() => { setShowFilterModal(true) }}> Filter ({[filterVenue, filterStaff, startIncidentFilterDate, endIncidentFilterDate].filter(r => r).length}) </button>
                    {!viewIncident && selectedRows.length > 0 && <button className='tabButtonActive' style={{ display: 'flex' }} onClick={() => { setShowExportModal(true) }}> Export </button>}
                    {!viewIncident && selectedRows.length > 0 && <button className='tabButtonActive' style={{ display: 'flex', color: 'green' }} onClick={handleMassApprove}> Approve </button>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    <Input errors={[]} value={search} setValue={setSearch} placeholder={'Search'} />
                    <button style={{ display: 'flex', marginLeft: '10px' }} disabled={loading} onClick={() => { setShowTypes(true); setType('') }}>+ New</button>
                </div>

            </div>}



            <ModalComponent showModal={showExportModal} setShowModal={setShowExportModal}>
                <h2>Export Incidents</h2>

                <Input value={reportName} setValue={setReportName} errors={[]} label={'Report Name'} placeholder={'Report 1'} />
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    {['Summary PDF', 'Complete PDF'].map(row =>
                        <div key={row} style={{ width: '50%' }}>
                            <Checkbox label={row} value={reportType === row} setValue={() => setReportType(row)} />
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    {['Summary Excel', 'Complete Excel'].map(row =>
                        <div key={row} style={{ width: '50%' }}>
                            <Checkbox label={row} value={reportType === row} setValue={() => setReportType(row)} />
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    <button disabled={!reportName || !reportType || loading} onClick={applyReport}>Export Report</button>
                    <button className='secondaryButton' onClick={() => setShowExportModal(false)}>Close</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showFilterModal} setShowModal={setShowFilterModal}>
                <h2>Filter</h2>
                <MyCalendar startDate={startIncidentFilterDate} setStartDate={setStartIncidentFilterDate} label={'Start Date'} />
                <MyCalendar minDate={startIncidentFilterDate ? { minDate: startIncidentFilterDate } : null} startDate={endIncidentFilterDate} setStartDate={setEndIncidentFilterDate} label={'End Date'} />

                <Dropdown errorFields={errors} name={'venue'} label={'Venue'} options={venues.map(({ id, name }) => ({ label: name, value: id }))} value={filterVenue} setValue={setFilterVenue} />
                <Dropdown errorFields={errors} name={'staff'} label={'Staff'} options={staff.map(({ id, firstName, lastName }) => ({ label: firstName + ' ' + lastName, value: id }))} value={filterStaff} setValue={setFilterStaff} />

                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    <button onClick={applyFilter}>Filter</button>
                    <button className='secondaryButton' onClick={clearFilters}>Clear Filters</button>
                </div>
            </ModalComponent>
            <ModalComponent showModal={showTypes} setShowModal={setShowTypes}>
                <h3 style={{ textAlign: 'center' }}>Select Type</h3>
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {types.map(t => <div key={t} className='hover' style={{ display: 'flex', padding: '15px', border: '1px solid #DCDCDC', color: type === t ? '#FFFFFF' : '#000000', backgroundColor: type !== t ? '#FFFFFF' : '#000000', borderRadius: '12px' }} onClick={() => { setType(t); handleCreateNewIncident() }}>
                        <label className='hover'>{t}</label>
                    </div>)}
                </div>
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {/* <button disabled={!type} onClick={handleCreateNewIncident}>Create</button> */}
                    <button className='secondaryButton' onClick={() => { setShowTypes(false); setType('') }}>Cancel</button>
                </div>
            </ModalComponent>


            <ModalComponent showModal={!!previewIncident} setShowModal={setPreviewIncident}>
                <h3 style={{ textAlign: 'center' }}>View Incident</h3>


                <div className='preview'>

                    {
                        ['Overview', 'History'].map((tab, index) => <>
                            <button key={tab} className={tab === previewMode ? '' : 'secondaryButton'} style={{ padding: '10px', marginRight: '10px', marginTop: 0 }} onClick={() => { setPreviewMode(tab) }}>{tab}</button>
                        </>)
                    }

                    {previewMode === 'History' ? <>
                        {previewIncident && previewIncident.events.map((event, index) =>
                            <div key={`event-${index}`}>
                                <label>{event.message}</label>
                                <label>{format(new Date(event.date), 'dd/MMM/yyyy HH:mm')}</label>

                                {(event.message.includes('Created') || event.message.includes('Updated')) && previewIncident.versions.filter((version, idx) => index === idx).map((version, idx) => <button className='textButton' style={{ margin: 0, marginLeft: '5px' }} onClick={() => { setShowVersion(version) }}>Show Version</button>)}
                            </div>)}

                    </> : <>
                        <div>
                            <label>Type:</label>
                            <label>{previewIncident.type}</label>
                        </div>
                        <div>
                            <label>Staff:</label>
                            <label>{getLabel(previewIncident.userId, staff, false, true)}</label>
                        </div>
                        <div>
                            <label>Report Date & Time:</label>
                            <label>{previewIncident && format(new Date(previewIncident.created), 'dd/MMM/yyyy, HH:mm')}</label>
                        </div>
                        <div>
                            <label>Incident Date & Time:</label>
                            <label>{previewIncident && format(new Date(previewIncident.incidentDate), 'dd/MMM/yyyy, HH:mm')}</label>
                        </div>
                        <div>
                            <label>Venue:</label>
                            <label>{getLabel(previewIncident.venue, venues)}</label>
                        </div>
                        <div>
                            <label>Incident Category:</label>
                            <label>{getLabel(previewIncident.category, categories, true)}</label>
                        </div>
                        <div>
                            <label>Summary:</label>
                            <label>{previewIncident.description}</label>
                        </div>
                        <div>
                            <label>Quantity:</label>
                            <label>{previewIncident.quantity}</label>
                        </div>
                        {previewIncident.type === types[1] && <>
                            <div>
                                <label>Police Attendance:</label>
                                <label>{previewIncident.policeAttendance}</label>
                            </div>
                            {previewIncident.policeAttendance === 'Yes' && <div>
                                <label>Police Name:</label>
                                <label>{previewIncident.policeName}</label>
                            </div>}

                            <div style={{ paddingTop: '10px', borderTop: '1px solid #DCDCDC' }}>
                                <label>Witness Details</label>
                            </div>
                            {
                                previewIncident.witnesses.map((witness, index) => <>
                                    <button className={index === previewWitnessIndex ? '' : 'secondaryButton'} style={{ padding: '10px', marginRight: '10px', marginTop: 0 }} onClick={() => { setPreviewWitnessIndex(index) }}>Witness {index + 1}</button>
                                </>)
                            }
                            {
                                previewIncident.witnesses.filter((r, idx) => idx === previewWitnessIndex).map((witness, index) => <>
                                    <div>
                                        <label> Name:</label>
                                        <label>{witness.name}</label>
                                    </div>
                                    <div>
                                        <label> Age:</label>
                                        <label>{witness.age}</label>
                                    </div>
                                    <div>
                                        <label> Gender:</label>
                                        <label>{witness.gender ? witness.gender.label : ''}</label>
                                    </div>
                                    <div>
                                        <label> Email:</label>
                                        <label>{witness.email}</label>
                                    </div>
                                    <div>
                                        <label> Other Information:</label>
                                        <label>{witness.other}</label>
                                    </div>
                                </>)
                            }



                            <div style={{ paddingTop: '10px', borderTop: '1px solid #DCDCDC' }}>
                                <label>Person of Interest Details</label>
                            </div>
                            {
                                previewIncident.interests.map((witness, index) => <>
                                    <button className={index === previewInterestIndex ? '' : 'secondaryButton'} style={{ padding: '10px', marginRight: '10px', marginTop: 0 }} onClick={() => setPreviewInterestIndex(index)}>Person {index + 1}</button>
                                </>)
                            }
                            {
                                previewIncident.interests.filter((r, idx) => idx === previewInterestIndex).map((interest, index) => <>
                                    <div>
                                        <label> Name:</label>
                                        <label>{interest.name}</label>
                                    </div>
                                    <div>
                                        <label> Age:</label>
                                        <label>{interest.age}</label>
                                    </div>
                                    <div>
                                        <label> Gender:</label>
                                        <label>{interest.gender ? interest.gender.label : ''}</label>
                                    </div>
                                    <div>
                                        <label> Email:</label>
                                        <label>{interest.email}</label>
                                    </div>
                                    <div>
                                        <label> Other Information:</label>
                                        <label>{interest.other}</label>
                                    </div>
                                </>)
                            }
                        </>}
                        <div>
                            <label>Status:</label>
                            <label>{previewIncident.status}</label>
                        </div>
                    </>}
                </div>

                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {previewIncident.status === 'Submitted' && activeOrg && activeOrg.role === 'admin' && <button disabled={false} style={{ backgroundColor: '#008000' }} onClick={() => handleApproveIncident(previewIncident)}>Approve</button>}
                    {previewIncident.status === 'Submitted' && <button disabled={false} onClick={() => { handleEdit(previewIncident); }}>Edit</button>}
                    <button className='secondaryButton' onClick={() => { setPreviewIncident(false) }}>Close</button>
                </div>
            </ModalComponent>



            <ModalComponent showModal={!!showVersion} setShowModal={setShowVersion}>
                <h3 style={{ textAlign: 'center' }}>Viewing Incident Version</h3>
                <div className='preview'>
                    <>
                        <div>
                            <label>Type:</label>
                            <label>{showVersion.type}</label>
                        </div>
                        <div>
                            <label>Staff:</label>
                            <label>{getLabel(showVersion.userId, staff, false, true)}</label>
                        </div>
                        <div>
                            <label>Report Date & Time:</label>
                            <label>{showVersion && format(new Date(showVersion.created), 'dd/MMM/yyyy, HH:mm')}</label>
                        </div>
                        <div>
                            <label>Incident Date & Time:</label>
                            <label>{showVersion && format(new Date(showVersion.incidentDate), 'dd/MMM/yyyy, HH:mm')}</label>
                        </div>
                        <div>
                            <label>Venue:</label>
                            <label>{getLabel(showVersion.venue, venues)}</label>
                        </div>
                        <div>
                            <label>Incident Category:</label>
                            <label>{getLabel(showVersion.category, categories, true)}</label>
                        </div>
                        <div>
                            <label>Summary:</label>
                            <label>{showVersion.description}</label>
                        </div>
                        <div>
                            <label>Quantity:</label>
                            <label>{showVersion.quantity}</label>
                        </div>
                        {showVersion.type === types[1] && <>
                            <div>
                                <label>Police Attendance:</label>
                                <label>{showVersion.policeAttendance}</label>
                            </div>
                            {showVersion.policeAttendance === 'Yes' && <div>
                                <label>Police Name:</label>
                                <label>{showVersion.policeName}</label>
                            </div>}

                            <div style={{ paddingTop: '10px', borderTop: '1px solid #DCDCDC' }}>
                                <label>Witness Details</label>
                            </div>
                            {
                                showVersion.witnesses.map((witness, index) => <>
                                    <button className={index === previewWitnessIndex ? '' : 'secondaryButton'} style={{ padding: '10px', marginRight: '10px', marginTop: 0 }} onClick={() => { setPreviewWitnessIndex(index) }}>Witness {index + 1}</button>
                                </>)
                            }
                            {
                                showVersion.witnesses.filter((r, idx) => idx === previewWitnessIndex).map((witness, index) => <>
                                    <div>
                                        <label> Name:</label>
                                        <label>{witness.name}</label>
                                    </div>
                                    <div>
                                        <label> Age:</label>
                                        <label>{witness.age}</label>
                                    </div>
                                    <div>
                                        <label> Gender:</label>
                                        <label>{witness.gender ? witness.gender.label : ''}</label>
                                    </div>
                                    <div>
                                        <label> Email:</label>
                                        <label>{witness.email}</label>
                                    </div>
                                    <div>
                                        <label> Other Information:</label>
                                        <label>{witness.other}</label>
                                    </div>
                                </>)
                            }



                            <div style={{ paddingTop: '10px', borderTop: '1px solid #DCDCDC' }}>
                                <label>Person of Interest Details</label>
                            </div>
                            {
                                showVersion.interests.map((witness, index) => <>
                                    <button className={index === previewInterestIndex ? '' : 'secondaryButton'} style={{ padding: '10px', marginRight: '10px', marginTop: 0 }} onClick={() => setPreviewInterestIndex(index)}>Person {index + 1}</button>
                                </>)
                            }
                            {
                                showVersion.interests.filter((r, idx) => idx === previewInterestIndex).map((interest, index) => <>
                                    <div>
                                        <label> Name:</label>
                                        <label>{interest.name}</label>
                                    </div>
                                    <div>
                                        <label> Age:</label>
                                        <label>{interest.age}</label>
                                    </div>
                                    <div>
                                        <label> Gender:</label>
                                        <label>{interest.gender ? interest.gender.label : ''}</label>
                                    </div>
                                    <div>
                                        <label> Email:</label>
                                        <label>{interest.email}</label>
                                    </div>
                                    <div>
                                        <label> Other Information:</label>
                                        <label>{interest.other}</label>
                                    </div>
                                </>)
                            }
                        </>}
                    </>
                    <button className='secondaryButton' disabled={loading} onClick={() => { setShowVersion(false) }}>Close</button>

                </div>
            </ModalComponent>

            {
                viewIncident ? <>
                    <div className='flexrow'>
                        <MyCalendar startDate={incidentDate} setStartDate={setIncidentDate} />
                        <Dropdown errorFields={errors} name={'venue'} label={'Venue'} options={venues.map(({ id, name }) => ({ label: name, value: id }))} value={venue} setValue={setVenue} />
                    </div>

                    <div className='flexrow'>
                        <Dropdown errorFields={errors} isMulti name='category' label={'Incident Category'} options={categories.filter(r => r.type === type).map(({ id, name }) => ({ label: name, value: id }))} value={category} setValue={setCategory} />
                        {/* <Dropdown errorFields={errors} isMulti name={'action'} label={'Action Taken'} options={actions.map(({ id, name }) => ({ label: name, value: id }))} value={action} setValue={setAction} /> */}
                        <Input errors={[]} value={quantity} setValue={setQuantity} label={'Quantity'} />

                    </div>
                    {!hideLiquorField && <RadioGroup value={policeAttendance} setValue={setPoliceAttendance} label={'Police Attendance'} options={['Yes', 'No']} />}
                    {!hideLiquorField && policeAttendance === 'Yes' && <Input errors={errors} value={policeName} setValue={setPoliceName} label={'Police Officer Name'} />}

                    <Input multiline errors={errors} name={'description'} value={description} setValue={setDescription} label={'Occurrence'} />
                    {!hideLiquorField && <MyDropzone storageLocation={`Attachments/${randomString()}`} label={'Attachment'} customMessage={'Drop files here to upload'} editing={true} errors={errors} allowPdf name={'document'} image={attachment} updateImage={(doc) => setAttachment(doc)} />}

                    {!hideLiquorField && <div>
                        <Accordion label={'Persons of Interest Details'}>
                            {
                                interests.map((tab, index) => <button style={{ marginTop: '10px', width: 'max-content' }} className={index === viewInterestIndex ? 'tabButtonActive hover' : 'tabButton hover'} key={`interest-${index}`} onClick={() => { toggleInterest(index) }}> Person {index + 1}</button>)
                            }
                            <div className='flexrow'>
                                <Input errors={[]} value={interestName} setValue={setInterestName} label={'Name'} />
                            </div>
                            <div className='flexrow'>
                                <Dropdown errorFields={errors} label={'Gender'} options={['Male', 'Female', 'Other'].map(val => ({ label: val, value: val }))} value={interestGender} setValue={setInterestGender} />
                                <Input errors={[]} value={interestAge} setValue={setInterestAge} label={'Age'} />
                            </div>
                            <div className='flexrow'>
                                <Input inputMode='email' errors={[]} value={interestEmail} setValue={setInterestEmail} label={'Email'} />
                                <Input inputMode='tel' errors={[]} value={interestPhone} setValue={setInterestPhone} label={'Phone'} />
                            </div>
                            <Input errors={[]} value={interestLicense} setValue={setInterestLicense} label={'License'} />
                            <Input errors={[]} multiline value={interestOther} setValue={setInterestOther} label={'Other Information'} />

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button className='textButton' onClick={() => { addInterest() }}>+ Add Person of Interest</button>
                                {interests.length !== 1 && <button className='textButton' style={{ color: 'red' }} onClick={() => { removeInterest() }}>Delete Person of Interest</button>}
                            </div>

                        </Accordion>

                        <Accordion label={'Witness Details'}>

                            {
                                witnesses.map((tab, index) => <button style={{ marginTop: '10px', width: 'max-content' }} className={index === viewWitnessIndex ? 'tabButtonActive hover' : 'tabButton hover'} key={`witness-${index}`} onClick={() => { toggleWitness(index) }}> Witness {index + 1}</button>)
                            }

                            <div className='flexrow'>
                                <Input errors={[]} value={witnessName} setValue={setWitnessName} label={'Name'} />
                            </div>
                            <div className='flexrow'>
                                <Dropdown errorFields={errors} label={'Gender'} options={['Male', 'Female', 'Other'].map(val => ({ label: val, value: val }))} value={witnessGender} setValue={setWitnessGender} />
                                <Input errors={[]} value={witnessAge} setValue={setWitnessAge} label={'Age'} />
                            </div>
                            <div className='flexrow'>
                                <Input errors={[]} value={witnessPhone} setValue={setWitnessPhone} label={'Phone'} />
                                <Input errors={[]} value={witnessEmail} setValue={setWitnessEmail} label={'Email'} />
                            </div>
                            <Input errors={[]} value={witnessLicense} setValue={setWitnessLicense} label={'License'} />
                            <Input errors={[]} multiline value={witnessOther} setValue={setWitnessOther} label={'Other Information'} />


                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button className='textButton' onClick={() => { addWitness() }}>+ Add Witness</button>
                                {witnesses.length !== 1 && <button className='textButton' style={{ color: 'red' }} onClick={() => { removeWitness() }}>Delete Witness</button>}
                            </div>
                        </Accordion>
                    </div>}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button className='secondaryButton' disabled={loading} onClick={() => { setViewIncident(false) }}>Cancel</button>
                        <button style={{ marginLeft: page === 0 ? 'auto' : '20px', display: 'flex' }} disabled={loading || !venue || !description || category.length == 0} onClick={handleSave}>{loading ? 'Loading...' : 'Save'}</button>
                    </div>
                </> : <>

                    <table style={{ marginTop: '20px' }} className='hide-mobile scroll-table'>
                        <thead>
                            <tr>
                                <th style={{ width: '40px' }}><Checkbox label={''} value={selectedRows.length === filteredIncidents.length} setValue={toggleAllSelected} /></th>
                                <th onClick={() => handleSort('reportId')}>Report ID <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('type')}>Type <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('userId')}>Staff <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('created')}>Report Date & Time <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('incidentDate')}>Incident Date & Time <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('venue')}>Venue <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('category')}>Incident Category <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('quantity')} style={{ width: '60px' }}>Quantity <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('description')} style={{ width: '200px' }}>Summary <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                                <th onClick={() => handleSort('status')}>Status <img src={SortIcon} style={{ width: '16px', height: '16px' }} /></th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredIncidents.map(row => <tr key={row.id}>
                                <th onClick={() => { }} style={{ width: '40px' }}><Checkbox label={''} value={selectedRows.map(r => r.id).includes(row.id)} setValue={() => toggleSelection(row)} /></th>
                                <td className='hover' onClick={() => { handlePreviewViewIncident(row); }} style={{ textDecoration: 'underline' }}>{row.reportId || '-'}</td>
                                <td>{row.type}</td>
                                <td>{row.staff}</td>
                                <td>{format(new Date(row.created), 'dd/MMM/yyyy, HH:mm')}</td>
                                <td>{format(new Date(row.incidentDate), 'dd/MMM/yyyy, HH:mm')}</td>
                                <td>{row.ven}</td>
                                <td>{row.cat}</td>
                                <td style={{ width: '60px' }}>{row.quantity}</td>
                                <td style={{ overflowWrap: 'break-word', width: '200px' }}>{(row.description && row.description.length >= 50) ? (row.description.substring(0, 50) + '...') : row.description}</td>
                                <td style={{ color: getStatusColor(row.status) }}>{row.status}</td>
                                {/* <td>
                                    <button className="textButton" onClick={() => handleEdit(row)}>Edit</button>
                                </td> */}
                            </tr>)}
                        </tbody>
                    </table>

                    {filteredIncidents.map(row => <table className='show-mobile' key={row.id} style={{ marginTop: '20px' }}>
                        <tbody>
                            <tr>
                                <th>Report Id</th>
                                <td className='hover' onClick={() => { handlePreviewViewIncident(row) }} style={{ textDecoration: 'underline' }}>{row.reportId || '-'}</td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>{row.type}</td>
                            </tr>
                            <tr>
                                <th>Staff</th>
                                <td>{row.staff}</td>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <td>{format(new Date(row.incidentDate), 'dd/MMM/yyyy, hh mm a')}</td>
                            </tr>
                            <tr>
                                <th>Venue</th>
                                <td>{row.ven}</td>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>{row.cat}</td>
                            </tr>
                            <tr>
                                <th>Quantity</th>
                                <td>{row.quantity}</td>
                            </tr>
                            <tr>
                                <th>Summary</th>
                                <td style={{ overflowWrap: 'break-word' }}>{row.description}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>{row.status}</td>
                            </tr>
                            <tr>
                                {/* <th></th> */}
                                {/* <td>
                                    <button className="textButton" onClick={() => handleEdit(row)}>Edit</button>
                                </td> */}
                            </tr>
                        </tbody>
                    </table>)}
                </>
            }
        </div >
    </div >)
}

export default Home