import React, { useState, useRef, useEffect } from 'react'

import Input from '../components/Input'
import Dropdown from '../components/Dropdown'
import { checkEmailInValid, getFirebaseDocs, getUrlParams, randomString, reverseDateString } from '../common'
import MyDropzone from '../components/MyDropzone'
import Checkbox from '../components/Checkbox'
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios'
import { format, isAfter, isBefore } from 'date-fns'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import MyCalendar from '../components/Calendar'
import Radio from '../components/Radio'
import { reportFrequencyHourly, reportOptions, startDateOptions, trainers, types } from '../constants'
import AddressSearch from '../components/AddressSearch'
import { useDispatch, useSelector } from 'react-redux'
import Header from "../components/Header";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { auth, db } from '../firebase-config'
import { setActiveOrg, setOrganisations, setUser } from '../redux/userReducer'
import ModalComponent from '../components/Modal'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

const Settings = () => {

    const [activeTab, setActiveTab] = useState('Users')
    const [activeSection, setActiveSection] = useState('Incident Categories')
    const [errors, setErrors] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [triggerRefetch, setTriggerRefetch] = useState(false)

    const [staffUsers, setStaffUsers] = useState([])
    const [schedules, setSchedules] = useState([])

    const [showModal, setShowModal] = useState(false)

    const [showDashboardMessage, setShowDashboardMessage] = useState(false)

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const [showAddOrgModal, setShowAddOrgModal] = useState(false)
    const [showAddScheduleReportModal, setShowAddScheduleReportModal] = useState(false)

    const [showAddHourlyReportModal, setShowAddHourlyReportModal] = useState(false)
    const [showAddNextDayReportModal, setShowAddNextDayReportModal] = useState(false)

    const [reportFormat, setReportFormat] = useState('')
    const [reportType, setReportType] = useState([])
    const [reportVenue, setReportVenue] = useState([])
    const [reportMode, setReportMode] = useState('')
    const [emails, setEmails] = useState('')

    const [showDeleteReportConfirm, setShowDeleteReportConfirm] = useState(false)
    const [showDeleteMessageConfirm, setShowDeleteMessageConfirm] = useState(false)

    const [startReportDate, setStartReportDate] = useState(new Date())
    const [endReportDate, setEndReportDate] = useState(new Date())
    const [interval, setInterval] = useState()

    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [value, setValue] = useState('') // Dashboard message value
    const [firstName, setFirstName] = useState('')
    const [status, setStatus] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('') // Only for new users
    const [role, setRole] = useState('')

    const [businessLogo, setBusinessLogo] = useState('')
    const [prefix, setPrefix] = useState('')

    const [messages, setMessages] = useState([])

    const [editId, setEditId] = useState('')

    const userInfo = useSelector((state) => state.user.user)
    const activeOrg = useSelector((state) => state.user.activeOrg)
    const venues = useSelector((state) => state.user.venues)
    const categories = useSelector((state) => state.user.categories)
    const actions = useSelector((state) => state.user.actions)
    const organisations = useSelector((state) => state.user.organisations)


    const dispatch = useDispatch()

    const [dropdownOptions, setDropdownOptions] = useState([])

    useEffect(() => {
        if (activeOrg) {
            fetchStaff(activeOrg.id)
            fetchOrgs()
            fetchStaticMessages()
            fetchSchedules(activeOrg.id)
        }
    }, [activeOrg])

    useEffect(() => {
        if (userInfo) {
            fetchOrgs()
        }
    }, [userInfo])

    useEffect(() => {
        if (triggerRefetch) {
            getUserInfo()
            fetchOrgs()
        }
    }, [triggerRefetch])

    useEffect(() => {

        if (reportMode) {
            if (reportMode === reportOptions[0]) {
                setShowAddHourlyReportModal(true)
            } else if (reportMode === reportOptions[1]) {
                setShowAddNextDayReportModal(true)
            }
        }
    }, [reportMode])


    const getUserInfo = async () => {
        const userData = ((await getDoc(doc(db, 'users', userInfo.id))).data())
        dispatch(setUser({ ...userData, id: userInfo.id }))
    }

    const fetchStaticMessages = async () => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'static'), where('orgId', '==', activeOrg.id))))

        setMessages(records.sort((a, b) => a.created > b.created ? 1 : -1))
    }

    const fetchOrgs = async () => {
        let options = [], orgs = []
        if (userInfo) {
            for (let orgId of userInfo.organisations.map(r => r.id)) {
                const record = (await getDoc(doc(db, 'organisations', orgId))).data()
                options.push({ label: record.name, value: orgId })
                orgs.push({ ...record, id: orgId })
            }
        }

        dispatch(setOrganisations(orgs))
        setDropdownOptions(options)
    }


    const fetchStaff = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'users'), where('organisations', 'array-contains-any', [{ id: orgId, role: 'admin' }, { id: orgId, role: 'user' }]))))
        setStaffUsers(records)
    }

    const fetchSchedules = async (orgId) => {
        const records = await getFirebaseDocs(await getDocs(query(collection(db, 'schedules'), where('orgId', '==', orgId))))
        setSchedules(records)
    }

    const tabs = ['Users', 'Config',]


    const configSections = ['Incident Categories', 'Venues', 'Reports', 'Business', 'Dashboard Messages']

    const getStaffRole = (staff) => {
        const matchingOrg = staff.organisations.find(r => r.id === activeOrg.id)
        if (matchingOrg && matchingOrg.role) {
            return matchingOrg.role[0].toUpperCase() + matchingOrg.role.substring(1,)
        }
        return ''
    }


    const handleAdd = () => {

        if (activeTab === tabs[0]) {
            setEditId(false)
            setFirstName('')
            setStatus('')
            setLastName('')
            setRole('')
            setEmail('')
            setPassword('')
            setShowAddUserModal(true)
        } else {
            setType('')
            if (activeTab === tabs[1] && activeSection === configSections[3]) {
                setShowAddOrgModal(true)
            } else if (activeTab === tabs[1] && activeSection === configSections[2]) {
                setReportMode('')
                setReportType('')
                setReportVenue('')
                setEmails('')
                setEditId('')
                setName('')
                setStartReportDate('')
                setReportFormat('')
                setEndReportDate('')
                setInterval('')
                setShowAddScheduleReportModal(true)
            } else if (activeTab === tabs[1] && activeSection === configSections[4]) {
                setShowDashboardMessage(true)
                setEditId('')
                setName('')
                setValue('')
            } else {
                setShowModal(true)
                setName('')
                setValue('')
            }
        }
    }


    const handleResetPassword = async () => {
        try {
            setLoading(true)

            // const token = await getAuth().currentUser.getIdToken()
            // const payload = {
            //     editId: showResetPasswordModal.id, orgId: activeOrg.id
            // }
            // console.log(payload)
            // await axios.post(`https://resetuserpassword-oqysrnziza-ts.a.run.app`, payload, { headers: { 'Authentication': token, 'Content-Type': 'application/json' } })

            await sendPasswordResetEmail(auth, showResetPasswordModal.email)
            toast.success('Password reset link sent to email.')
            setLoading(false)
        } catch (e) {
            console.error('error', e)
            setLoading(false)
            toast.error('Error occurred')
        }
        setShowResetPasswordModal(false)
    }

    const handleSave = async () => {
        let dbName = ''

        if (activeTab === tabs[0]) {
            dbName = 'users'
            const payload = {
                firstName, lastName, status: !editId ? 'Active' : status.value, email, role: role.value.toLowerCase(), editId, orgId: activeOrg.id, password
            }

            try {

                const token = await getAuth().currentUser.getIdToken()
                setLoading(true)
                await axios.post(`https://user-oqysrnziza-ts.a.run.app`, payload, { headers: { 'Authentication': token, 'Content-Type': 'application/json' } })
                fetchStaff(activeOrg.id)
                toast.success(editId ? 'User updated' : 'User created')
                setLoading(false)
            } catch (e) {
                console.error('error', e)
                toast.error('Error occurred' + e.response && e.response.data ? e.response.data.err : '')
                setLoading(false)
            }
            setShowAddUserModal(false)

        } else if (activeTab === tabs[1]) {
            let payload = {}
            if (activeSection === configSections[0]) {
                payload = { type: type.value }
                dbName = 'categories'
            }
            else if (activeSection === configSections[1]) {
                dbName = 'venues'
            }
            if (editId) {
                await updateDoc(doc(db, dbName, editId), { name: name.trim(), ...payload, orgId: activeOrg.id })
                toast.success('Updated document', { position: 'top-center' })

            } else {
                await addDoc(collection(db, dbName), { name: name.trim(), ...payload, orgId: activeOrg.id })
                toast.success('Created document', { position: 'top-center' })
            }
        }
        setTriggerRefetch(true)
        setShowModal(false)
        setEditId('')
        setName('')
        setType('')
        fetchStaticMessages()
    }

    const handleSaveDashboardMessage = async () => {
        const dbName = 'static'
        if (editId) {
            await updateDoc(doc(db, dbName, editId), { name: name.trim(), value: value, orgId: activeOrg.id })
            toast.success('Updated document', { position: 'top-center' })

        } else {
            await addDoc(collection(db, dbName), { name: name.trim(), value: value, orgId: activeOrg.id, created: new Date().toISOString() })
            toast.success('Created document', { position: 'top-center' })
        }

        setTriggerRefetch(true)
        setShowDashboardMessage(false)
        setEditId('')
        setName('')
        fetchStaticMessages()
    }

    const handleSaveOrg = async () => {

        if (editId) {

            const matchingOrg = organisations.find(r => r.id === editId)
            await updateDoc(doc(db, 'organisations', editId), { ...matchingOrg, name: name.trim(), image: businessLogo ? businessLogo.url : '' })
            toast.success('Updated organisation', { position: 'top-center' })
        } else {

            const orgDoc = await addDoc(collection(db, 'organisations'), { name: name.trim(), image: businessLogo ? businessLogo.url : '', prefix: prefix.trim() })

            // Add org to users list of orgs if not created by super admin.
            if (!userInfo.isSuperAdmin) {
                await updateDoc(doc(db, 'users', userInfo.id), { organisations: [...userInfo.organisations, { id: orgDoc.id, role: 'admin' }] })
            }

            toast.success('Added organisation', { position: 'top-center' })

        }

        setPrefix('')

        setBusinessLogo('')
        setTriggerRefetch(true)
        setName('')
        setEditId('')
        setShowAddOrgModal(false)
    }

    const handleSaveSchedule = async () => {

        let payload = {}, hasInvalidEmail = false

        let emailValues = emails.split(',').map(r => r && r.trim()).filter(r => r)

        for (let emailVal of emailValues) {
            if (checkEmailInValid(emailVal)) {
                hasInvalidEmail = true
            }
        }

        if (hasInvalidEmail) {
            toast.error('Check your emails. Invalid email found.', { position: 'top-center' })
            return
        }

        if (reportMode === reportOptions[1]) {
            payload = {
                startReportDate: startReportDate.value,
                reportFormat: reportFormat.value,
                interval: interval.value,
                emails,
                reportType: reportType.map(r => r.value),
                reportVenue: reportVenue.map(r => r.value),
                name,
                orgId: activeOrg.id,
                userId: userInfo.id,
                reportMode: reportMode,
            }

        } else {

            const startReportTimeIndex = startDateOptions.findIndex(r => r === startReportDate.value)
            const endReportTimeIndex = startDateOptions.findIndex(r => r === endReportDate.value)

            if (endReportTimeIndex <= startReportTimeIndex) {
                toast.error('End Report Time should be after Start Time', { position: 'top-center' })
                return
            }

            payload = {
                startReportDate: startReportDate.value,
                reportFormat: reportFormat.value,
                endReportDate: endReportDate.value,
                reportType: reportType.map(r => r.value),
                reportVenue: reportVenue.map(r => r.value),
                interval: interval.value,
                emails,
                name,
                orgId: activeOrg.id,
                userId: userInfo.id,
                reportMode: reportMode,
            }
        }

        if (editId) {
            await updateDoc(doc(db, 'schedules', editId), payload)
            toast.success('Updated report schedule', { position: 'top-center' })
        } else {
            await addDoc(collection(db, 'schedules'), payload)
            toast.success('Added report schedule', { position: 'top-center' })
        }

        setName('')
        setEditId('')
        setStartReportDate('')
        setReportFormat('')
        setInterval('')
        setReportType('')
        setReportVenue('')
        setReportMode('')
        setEmails('')
        // setShowAddScheduleReportModal(false)
        setShowAddNextDayReportModal(false)
        setShowAddHourlyReportModal(false)
        fetchSchedules(activeOrg.id)
    }

    const handleUserEdit = (user) => {
        setEditId(user.id)
        setFirstName(user.firstName)
        setStatus({ label: user.status, value: user.status })
        setLastName(user.lastName)
        setEmail(user.email)
        setRole({ label: getStaffRole(user), value: getStaffRole(user) })
        setShowAddUserModal(true)
    }



    const handleDeleteReport = async () => {
        await deleteDoc(doc(db, 'schedules', editId))
        setShowDeleteReportConfirm(false)
        setEditId('')
        fetchSchedules(activeOrg.id)
    }

    const handleDeleteMessage = async () => {
        await deleteDoc(doc(db, 'static', editId))
        setShowDeleteMessageConfirm(false)
        setEditId('')
        fetchStaticMessages()
    }


    const sortAlphabetically = (arrayVal, property) => {
        let arrayOriginal = [...arrayVal]
        if (!property) {
            return arrayOriginal.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
        }
        return arrayOriginal.sort((a, b) => a[property].toLowerCase() < b[property].toLowerCase() ? -1 : 1)

    }

    return (<div>
        <Header triggerRefetch={triggerRefetch} setTriggerRefetch={setTriggerRefetch} />
        <div style={{ margin: '10px' }}>
            {errorMessage && errors.length > 0 && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            <div className='tabBar'>
                {
                    tabs.map(tab => <button style={{ marginTop: 0, }} className={activeTab === tab ? 'tabButtonActive hover' : 'tabButton hover'} key={tab} onClick={() => { setActiveTab(tab) }}> {tab}</button>)
                }
                <div style={{ marginTop: '-25px', marginLeft: 'auto' }}>
                    <Dropdown errorFields={errors} name={'activeOrgId'} label={''} options={dropdownOptions} value={dropdownOptions.find(r => r.value === activeOrg.id)} setValue={v => {
                        dispatch(setActiveOrg(userInfo.organisations.find(r => r.id === v.value)))
                    }} />
                </div>

            </div>
            {activeTab === tabs[1] && <div className='tabBar' style={{ marginTop: -20 }}>
                {
                    configSections.map(tab => <button style={{ marginTop: 0, width: 'max-content' }} className={activeSection === tab ? 'tabButtonActive hover' : 'tabButton hover'} key={tab} onClick={() => { setActiveSection(tab) }}> {tab}</button>)
                }
            </div>}

            <button style={{ margin: '10px 0 10px auto', display: 'flex' }} disabled={loading} onClick={handleAdd}>+ New</button>



            <ModalComponent showModal={showDeleteReportConfirm} setShowModal={setShowDeleteReportConfirm}>
                <h3 style={{ textAlign: 'center' }}>Delete this report?</h3>

                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    <button disabled={false} onClick={handleDeleteReport}>Delete</button>
                    <button className='secondaryButton' onClick={() => { setShowDeleteReportConfirm(false); setEditId('') }}>Cancel</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showDeleteMessageConfirm} setShowModal={setShowDeleteMessageConfirm}>
                <h3 style={{ textAlign: 'center' }}>Delete this Message?</h3>

                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    <button disabled={false} onClick={handleDeleteMessage}>Delete</button>
                    <button className='secondaryButton' onClick={() => { setShowDeleteMessageConfirm(false); setEditId('') }}>Cancel</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showAddScheduleReportModal} setShowModal={setShowAddScheduleReportModal}>
                <h3 style={{ textAlign: 'center' }}>Select Type</h3>
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {reportOptions.map(t => <div key={t} className='hover' style={{ display: 'flex', padding: '15px', border: '1px solid #DCDCDC', color: '#000000', backgroundColor: '#FFFFFF', borderRadius: '12px' }} onClick={() => { setReportMode(t); setShowAddScheduleReportModal(false) }}>
                        <label className='hover'>{t}</label>
                    </div>)}
                </div>
                <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                    {/* <button disabled={!type} onClick={handleCreateNewIncident}>Create</button> */}
                    <button className='secondaryButton' onClick={() => { setShowAddScheduleReportModal(false); setReportMode('') }}>Cancel</button>
                </div>
            </ModalComponent>


            <ModalComponent showModal={showAddNextDayReportModal} setShowModal={setShowAddNextDayReportModal}>
                <h2>{editId ? 'Edit' : 'Add'} Next Day Report</h2>

                <Input value={name} setValue={setName} errors={[]} label={'Name'} />
                <Input value={emails} setValue={setEmails} errors={[]} label={'Emails'} placeholder={'email@domain.com, email2@domain.com'} />
                <Dropdown isMulti errorFields={errors} name={'venue'} label={'Venue'} options={venues.map(r => ({ label: r.name, value: r.id }))} value={reportVenue} setValue={setReportVenue} />
                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ width: '48%' }}>
                        <Dropdown isMulti errorFields={errors} name={'report-type'} label={'Report Type'} options={types.map(r => ({ label: r, value: r }))} value={reportType} setValue={setReportType} />
                    </div>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'report-date'} label={'Report Time'} options={startDateOptions.map(r => ({ label: r, value: r }))} value={startReportDate} setValue={setStartReportDate} />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'report-format'} label={'Report Format'} options={['Summary PDF', 'Complete PDF'].map(r => ({ label: r, value: r }))} value={reportFormat} setValue={setReportFormat} />
                    </div>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'interval'} label={'Report Period'} options={['Past 12 Hours', 'Past 24 Hours'].map(r => ({ label: r, value: r }))} value={interval} setValue={setInterval} />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowAddNextDayReportModal(false)}>Cancel</button>
                    <button disabled={!name || !startReportDate || !interval || !reportFormat || loading || !emails} onClick={handleSaveSchedule}>Save</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showAddHourlyReportModal} setShowModal={setShowAddHourlyReportModal}>
                <h2>{editId ? 'Edit' : 'Add'} Hourly Report</h2>

                <Input value={name} setValue={setName} errors={[]} label={'Name'} />
                <Input value={emails} setValue={setEmails} errors={[]} label={'Emails'} placeholder={'email@domain.com, email2@domain.com'} />
                <Dropdown isMulti errorFields={errors} name={'venue'} label={'Venue'} options={venues.map(r => ({ label: r.name, value: r.id }))} value={reportVenue} setValue={setReportVenue} />
                <Dropdown isMulti errorFields={errors} name={'report-type'} label={'Report Type'} options={types.map(r => ({ label: r, value: r }))} value={reportType} setValue={setReportType} />
                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'report-date'} label={'Report Start Time'} options={startDateOptions.map(r => ({ label: r, value: r }))} value={startReportDate} setValue={setStartReportDate} />
                    </div>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'report-end-date'} label={'Report End Time'} options={startDateOptions.map(r => ({ label: r, value: r }))} value={endReportDate} setValue={setEndReportDate} />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'report-format'} label={'Report Format'} options={['Summary PDF', 'Complete PDF'].map(r => ({ label: r, value: r }))} value={reportFormat} setValue={setReportFormat} />
                    </div>
                    <div style={{ width: '48%' }}>
                        <Dropdown errorFields={errors} name={'interval'} label={'Report Frequency'} options={reportFrequencyHourly.map(r => ({ label: r, value: r }))} value={interval} setValue={setInterval} />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowAddHourlyReportModal(false)}>Cancel</button>
                    <button disabled={!name || !reportType || !startReportDate || !reportFormat || !endReportDate || !interval || loading || !emails} onClick={handleSaveSchedule}>Save</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showAddOrgModal} setShowModal={setShowAddOrgModal}>
                <h2>{editId ? 'Edit' : 'Add'} Business</h2>
                <Input value={name} setValue={setName} errors={[]} label={'Name'} />
                {!editId && <Input value={prefix} setValue={setPrefix} errors={[]} label={'Prefix'} />}
                <MyDropzone storageLocation={`Logo/${randomString()}`} label={'Logo'} customMessage={'Drop files here to upload'} editing={true} errors={errors} name={'document'} image={businessLogo} updateImage={(doc) => setBusinessLogo(doc)} />

                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowAddOrgModal(false)}>Cancel</button>
                    <button disabled={!name || !prefix || loading} onClick={handleSaveOrg}>Save</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showAddUserModal} setShowModal={setShowAddUserModal}>
                <h2>{editId ? 'Edit' : 'Add'} {activeTab === tabs[0] ? 'User' : activeTab === tabs[1] ? activeSection : ''}</h2>
                <Input value={firstName} setValue={setFirstName} errors={[]} label={'First Name'} />
                <Input value={lastName} setValue={setLastName} errors={[]} label={'Last Name'} />
                <Input inputMode={'email'} value={email} setValue={setEmail} errors={[]} label={'Email'} />
                {!editId && <Input inputMode={'password'} value={password} setValue={setPassword} errors={[]} label={'Password'} />}
                {!editId && <label>Password must be alteast 6 characters</label>}
                <Dropdown errorFields={errors} name={'role'} label={'Role'} options={['Admin', 'User'].map(r => ({ label: r, value: r }))} value={role} setValue={setRole} />
                <Dropdown errorFields={errors} name={'status'} label={'Status'} options={['Active', 'Inactive'].map(r => ({ label: r, value: r }))} value={status} setValue={setStatus} />

                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowAddUserModal(false)}>Cancel</button>
                    <button disabled={!role || !firstName || !lastName || !status || !email || (!editId && (!password || password.length < 6)) || checkEmailInValid(email) || loading} onClick={handleSave}>Save</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={!!showResetPasswordModal} setShowModal={setShowResetPasswordModal}>
                <h2>Reset password </h2>
                <p>Reset password link will be sent to user's email: {showResetPasswordModal.email}. </p>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowResetPasswordModal(false)}>Cancel</button>
                    <button disabled={loading} onClick={handleResetPassword}>Send</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showModal} setShowModal={setShowModal}>
                <h2>{editId ? 'Edit' : 'Add'} {activeTab === tabs[0] ? 'User' : activeTab === tabs[1] ? activeSection : ''}</h2>
                <Input value={name} setValue={setName} errors={[]} label={'Name'} />
                {activeTab === tabs[1] && configSections[0] === activeSection && <Dropdown errorFields={errors} name={'type'} label={''} options={types.map(r => ({ label: r, value: r }))} value={type} setValue={setType} />}
                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowModal(false)}>Cancel</button>
                    <button disabled={loading || (activeTab === tabs[1] && configSections[0] === activeSection && !type)} onClick={handleSave}>Save</button>
                </div>
            </ModalComponent>

            <ModalComponent showModal={showDashboardMessage} setShowModal={setShowDashboardMessage}>
                <h2>{editId ? 'Edit' : 'Add'} Dashboard Message</h2>
                <Input value={name} setValue={setName} errors={[]} label={'Name'} />
                <Input value={value} setValue={setValue} errors={[]} label={'Value'} />
                <div style={{ display: 'flex', gap: '20px' }}>
                    <button className='secondaryButton' onClick={() => setShowDashboardMessage(false)}>Cancel</button>
                    <button disabled={loading} onClick={handleSaveDashboardMessage}>Save</button>
                </div>
            </ModalComponent>

            {activeTab === tabs[0] ? <>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th style={{ width: '150px' }}>Status</th>
                            <th style={{ width: '150px' }}>Role</th>
                            <th style={{ width: '150px' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortAlphabetically(staffUsers, 'firstName').map(staff => <tr key={staff.id}>
                            <td style={{ wordBreak: 'break-all' }}>{staff.firstName} {staff.lastName}</td>
                            <td style={{ wordBreak: 'break-all' }}>{staff.email}</td>
                            <td style={{ width: '150px' }}>{staff.status}</td>
                            <td style={{ width: '150px' }}>{getStaffRole(staff)}</td>
                            <td style={{ width: '150px' }}>
                                <button className="textButton" onClick={() => handleUserEdit(staff)}>Edit</button>
                                <button className="textButton" onClick={() => setShowResetPasswordModal(staff)}>Reset Password</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>

            </> : activeTab === tabs[1] ? <>

                {activeSection === configSections[0] ? <>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortAlphabetically(categories).map(cat => <tr key={cat.id}>
                                <td>{cat.name}</td>
                                <td>{cat.type}</td>
                                <td>
                                    <button className="textButton" onClick={() => { setEditId(cat.id); setName(cat.name); cat.type ? setType({ label: cat.type, value: cat.type }) : setType(''); setShowModal(true) }}>Edit</button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </>
                    :
                    activeSection === configSections[1] ? <>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortAlphabetically(venues).map(venue => <tr key={venue.id}>
                                    <td>{venue.name}</td>
                                    <td>
                                        <button className="textButton" onClick={() => { setEditId(venue.id); setName(venue.name); setShowModal(true) }}>Edit</button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </>
                        : activeSection === configSections[2] ? <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Report Format</th>
                                        <th>Report Mode</th>
                                        <th>Report Type</th>
                                        <th>Interval</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortAlphabetically(schedules).map(schedule => <tr key={schedule.id}>
                                        <td>{schedule.name}</td>
                                        <td>{schedule.startReportDate}</td>
                                        <td>{schedule.endReportDate || '-'}</td>
                                        <td>{schedule.reportFormat}</td>
                                        <td>{schedule.reportMode}</td>
                                        <td>{schedule.reportType && schedule.reportType.join(', ')}</td>
                                        <td>{schedule.interval}</td>
                                        <td>
                                            <button className="textButton" onClick={() => { setStartReportDate({ label: schedule.startReportDate, value: schedule.startReportDate }); setEmails(schedule.emails); setReportFormat({ label: schedule.reportFormat, value: schedule.reportFormat }); setEndReportDate(endReportDate ? { label: schedule.endReportDate, value: schedule.endReportDate } : ''); setInterval({ label: schedule.interval, value: schedule.interval }); setReportVenue((schedule.reportVenue || []).map(r => venues.find(v => v.id === r) && ({ label: venues.find(v => v.id === r).name, value: venues.find(v => v.id === r).id })).filter(r => r)); setReportType(schedule.reportType.map(row => ({ label: row, value: row }))); setName(schedule.name); schedule.reportMode === reportOptions[1] && setShowAddNextDayReportModal(true); schedule.reportMode === reportOptions[0] && setShowAddHourlyReportModal(true); setReportMode(schedule.reportMode); setEditId(schedule.id) }}>Edit</button>
                                            <button className="textButton" onClick={() => { setEditId(schedule.id); setShowDeleteReportConfirm(true) }}>Delete</button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>

                        </> : activeSection === configSections[3] ? <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Prefix</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortAlphabetically(organisations).map(org => <tr key={org.id}>
                                        <td>{org.name}</td>
                                        <td>{org.prefix}</td>
                                        <td>{org.image && <img src={org.image} style={{ width: '50px', height: '50px' }} />}</td>
                                        <td>
                                            <button className="textButton" onClick={() => { setPrefix(org.prefix); setBusinessLogo(org.image ? { url: org.image } : false); setName(org.name); setShowAddOrgModal(true); setEditId(org.id) }}>Edit</button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </> : <>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Message</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {messages.map(message => <tr key={message.id}>
                                        <td>{message.name}</td>
                                        <td>{message.value}</td>
                                        <td>
                                            <button className="textButton" onClick={() => { setName(message.name); setValue(message.value); setShowDashboardMessage(true); setEditId(message.id) }}>Edit</button>
                                            <button className="textButton" onClick={() => { setEditId(message.id); setShowDeleteMessageConfirm(true) }}>Delete</button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </>}
            </> : <>


            </>}

        </div>
    </div>)
}

export default Settings